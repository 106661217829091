import logo from '../src/assets/Logo_removebg.png';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Navbar } from './components/navbar';
import { Homepage } from './pages/homepage';
import { Services } from './pages/services';
import { Footer } from './components/footer';
import { Aboutus } from './pages/aboutus';
import { Ourteam } from './pages/ourteam';

function App() {
  return (
  <>
    <Navbar></Navbar>
    <div className='AppContainer'>
      <Routes>
          <Route path='/' element={<Homepage/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/aboutus' element={<Aboutus/>}/>
          <Route path='/ourteam' element={<Ourteam/>}/>
      </Routes>
      <Footer></Footer>
    </div>
  </>
  );
}

export default App;
