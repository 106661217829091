import React from "react";
import './aboutus.css';


export const Ourteam = () => {
    return(
        <>
        <div className="Pagecontent" >

        <div className="Modern"> 
        <img className="Cardimgf"  src={require("../assets/D. & R. Kasso black background.JPG")} alt="Kasso"/>
            <div className="Cardcontent">
            <div className="Cardtitle"> About Us </div>

            <div className="Carddescription">
        The legal team at Kasso Law Firm is dedicated to reuniting families and empowering them to achieve the American dream. With years of immigration law experience, our team offers comprehensive guidance through the complex immigration legal process. We provide personalized legal representation to effectively navigate the immigration system.

<br></br><br></br>
At Kasso Law Firm, we are deeply committed to reuniting families. With extensive experience in immigration law, we work tirelessly to support you and your loved ones throughout the process. 


Our immigration law firm has extensive experience handling a wide range of immigration matters, including family-based petitions, marriage-based and fiancé visas, citizenship and naturalization, U-visas, VAWA, T-visas, inadmissibility waivers, DACA and advance parole, asylum, and more.


Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. ¡Hablamos Español!
</div></div>
        </div>
        <div className="Title"> Our Team </div>
        <div className="Card"> 
            <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/D. Kasso with white background.JPG")} alt="Kasso"/>
            </div>
            <div className="Cardcontent">
                <div className="Cardtitle"> Denny Kasso, Esq. </div>
                <div className="Carddescription"> Denny Kasso is an accomplished immigration attorney with over a decade of experience in the field. She holds a Juris Doctorate from the University of California College of the Law, San Francisco, and has externed for the United States District Court, Northern District of California. Mrs. Kasso has represented clients before immigration courts and superior courts.
                As an immigrant herself, Mrs. Kasso has a deep personal understanding of the U.S. immigration process. She has successfully assisted thousands of immigrants with a wide range of petitions, including family-based, humanitarian, and naturalization applications. Mrs. Kasso's extensive expertise covers areas such as legal permanent residency, naturalization, fiancé visas, provisional waivers, U-visas for crime victims, the Violence Against Women Act (VAWA), T-visas, Special Immigrant Juvenile Status (SIJS), Deferred Action for Childhood Arrivals (DACA), and travel permits — even for clients with past criminal issues. Driven by a passion for reuniting families and paving the way to a brighter future, Mrs. Kasso brings a wealth of knowledge and compassion to her work at this office. Mrs. Kasso is fluent in English and Spanish.  </div>
            </div>
        </div>
        <div className="Card"> 

            <div className="Cardcontent">
                <div className="Cardtitle"> Ryan Kasso, Master of Laws </div>
                <div className="Carddescription"> Mr. Kasso is a seasoned professional with a wealth of expertise in the field of contract drafting and negotiation. Whether he is drafting a complex commercial contract or negotiating the terms of a high-stakes business deal, Mr. Kasso's keen eye for detail and nuanced understanding of legal terminology allow him to anticipate potential pitfalls and craft mutually beneficial solutions. His ability to meticulously review contract language, identify ambiguities, and negotiate favorable terms is truly remarkable.  </div>
            </div>
            <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/R. Kasso White Background.JPG")} alt="Kasso"/>
            </div>
        </div>
        </div>
        </>
    )
};