import React from "react";
import './aboutus.css';


export const Services = () => {
    return(
        <>
        <div className="Pagecontent">
        <div className="Title"> Services</div>

        <div className="Cardholder" id="1">
            <div className="Card">
            <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/1.jpg")} alt="Kasso"/>
            </div>
            <div className="Cardcontent">
                <div className="Cardtitle"> Family-based Petitions</div>
                <div className="Carddescription"> Family-based petitions are a crucial pathway for individuals seeking to immigrate to the United States and reunite with their loved ones. This process allows U.S. citizens and lawful permanent residents to sponsor certain family members, such as spouses, parents, children, and siblings, for permanent residency. The petitioner must demonstrate a valid familial relationship and meet various eligibility requirements set forth by U.S. immigration laws.
The experienced immigration lawyers at Kasso Law Firm are uniquely qualified to provide clients with the immigration advice and support they need to meet Green Card requirements and secure their immigration status, regardless of whether their significant other is located within the United States or abroad. 
Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. ¡Hablamos Español!</div>
            </div>
            {/* <div className="curve"></div> */}
        </div>
        </div>

        <div className="Cardholder">

        <div className="Card" id="2"> 
            <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/2.jpg")} alt="Kasso"/>
            </div>
            <div className="Cardcontent">
                <div className="Cardtitle"> Marriage-based Green Cards   </div>
                <div className="Carddescription"> Marriage-based green cards and fiancé visas are important pathways for foreign nationals seeking to immigrate to the United States by virtue of their relationship with a U.S. citizen or lawful permanent resident. The marriage-based green cards process involves the U.S. citizen filing a petition on behalf of their foreign-born spouse, demonstrating the legitimacy of the marriage and the sponsor's ability to financially support the immigrant. Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. ¡Hablamos Español!</div>
            </div>
        </div>


        <div className="Card" id="3"> 
            <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/3.jpg")} alt="Kasso"/>
            </div>
            <div className="Cardcontent">
                <div className="Cardtitle"> Fiancé Visas  </div>
                <div className="Carddescription"> Fiancé visas, on the other hand, enable a U.S. citizen to bring their foreign-citizen fiancé to the U.S. with the intent to marry within 90 days. Navigating the complexities of these visas’ pathways can be daunting. 
The experienced immigration lawyers at Kasso Law Firm possess the legal expertise to guide you smoothly through the marriage-based green card application and fiancé visa process. Our attorneys have dedicated years to helping families navigate such complexities, ensuring loved ones can legally reunite in the United States. 
Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. ¡Hablamos Español!
</div>
            </div>
        </div>


        <div className="Card" id="4"> 
            <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/4.jpg")} alt="Kasso"/>
            </div>
            <div className="Cardcontent">
                <div className="Cardtitle"> Citizenship & Naturalization               </div>
                <div className="Carddescription"> 
Naturalization is the legal process by which a non-citizen or foreign national becomes a citizen of a particular country. This is a significant milestone for many individuals, as it grants them full rights and responsibilities of citizenship, allowing them to actively participate in the political and civic life of their new homeland. The naturalization process typically involves demonstrating a certain period of continuous residency, passing language and civics exams to prove one's understanding of the country's history and government, and swearing an oath of allegiance. 
For those seeking to become naturalized citizens, this can be a lengthy and complex journey, requiring patience, dedication, and a genuine commitment to the values and principles of their prospective nation. 
If you or loved ones are seeking citizenship, be sure to reach out to the seasoned citizenship and naturalization attorneys at Kasso Law Firm. Call us today at (650) 770-6557 or (209) 740-2940. ¡Hablamos Español!
</div>
            </div>
        </div>
        

        <div className="Card" id="5"> 
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/5.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">U-visas</div>
            <div className="Carddescription">
                The U-visa is a specialized type of visa that provides temporary legal status and work authorization for non-citizens who are victims of certain crimes and who have been helpful to law enforcement or government officials in the investigation or prosecution of those crimes.
                <br></br>
                However, not every individual and not every crime qualifies. To learn more about eligibility, call Kasso Law Firm at (650) 770-6557 or (209) 740-2940 to discuss your options. ¡Hablamos Español!
            </div>
        </div>
    </div>

    <div className="Card" id="6">  
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/6.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">VAWA</div>
            <div className="Carddescription">
                The Violence Against Women Act (VAWA) program provides a secure path to legal immigration status for relatives of U.S. citizens or legal residents who have experienced family-related issues. The program is available to both parents of adult U.S. citizen children over 21 and spouses of citizens or residents, ensuring complete discretion to protect you and your family. Our specialized team is dedicated to guiding you through the process and addressing any inquiries you may have every step of the way.
                <br></br>
                Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. ¡Hablamos Español!
            </div>
        </div>
    </div>

    <div className="Card" id="7"> 
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/7.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">T-visas</div>
            <div className="Carddescription">
                A T-visa is a specialized immigration status granted to individuals who have been victims of human trafficking. This visa provides a critical pathway to legal residency and protection for those who have suffered the harrowing experience of being trafficked, whether for forced labor, sexual exploitation, or other forms of modern slavery.
                <br></br>
                Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. ¡Hablamos Español!
            </div>
        </div>
    </div>

    <div className="Card" id="8"> 
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/8.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">Inadmissibility Waivers</div>
            <div className="Carddescription">
                Inadmissibility waivers are an important legal mechanism that can allow certain foreign nationals to overcome barriers to entry into a country, despite factors that would typically render them inadmissible. Successfully obtaining an inadmissibility waiver requires navigating a complex legal process.
                <br></br>
                If you or a loved one is seeking an inadmissibility waiver, contact us today at (650) 770-6557 or (209) 740-2940. ¡Hablamos Español!
            </div>
        </div>
    </div>
    </div>
    <div className="Cardholder">


    <div className="Card" id="9"> 
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/9.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">DACA</div>
            <div className="Carddescription">
            DACA, or the Deferred Action for Childhood Arrivals program, is an immigration policy that provides temporary protection from deportation and work authorization for certain young undocumented immigrants who were brought to the United States as children.</div>
        </div>
    </div>


    <div className="Card" id="10"> 
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/10.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">Advance Parole
            </div>
            <div className="Carddescription">
            Advance parole is a crucial immigration procedure that allows certain foreign nationals to temporarily leave the United States and then legally re-enter, even if they do not have a valid visa or immigration status. 


To learn more about eligibility call Kasso Law Firm at (650) 770-6557 or (209) 740-2940 to discuss your options. ¡Hablamos Español!</div>
        </div>
        
    </div>


    <div className="Card" id="11"> 
        <div className="Cardimg">
            <img className="Cardimg" src={require("../assets/11.jpg")} alt="Kasso"/>
        </div>
        <div className="Cardcontent">
            <div className="Cardtitle">Asylum</div>
            <div className="Carddescription">
                Individuals who have suffered or fear persecution in their home country due to their race, religion, nationality, social group membership, or political opinion may be eligible for asylum in the United States.
                <br></br>
                Schedule a consultation today to explore your eligibility for asylum. ¡Hablamos Español!
            </div>
        </div>
    </div>
    </div>



        </div>
        </>
    )
};