import React from "react";
import './homepage.css';
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from "react";
import EmblaCarousel from '../js/EmblaCarousel';
import '../css/base.css'
import '../css/sandbox.css'
import '../css/embla.css'

export const Homepage = () => {
    const OPTIONS = { align: 'start' }
    const SLIDE_COUNT = 11
    const SLIDES = Array.from(Array(SLIDE_COUNT).keys())
      
    return(
        <>
        <div className="fPagecontent">

        <img className="Welcome" src={"https://upload.wikimedia.org/wikipedia/commons/d/d3/Golden_Gate_Bridge_at_sunset_1.jpg"} alt="Kasso"/>
            <section className="Boxed">
            <section className="section1">
            <div className="Slogan">
                <div className="Slogantitle"><i>Reuniting Families,<br></br> ‎ ‎ ‎ Realizing Dreams <br></br>  ¡Hablamos Español!</i></div>
                <div className="Follow">Follow us:  
                    
                <a href="https://www.youtube.com/@abogadaenusa5581" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" className={"Elelogof"}><path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"/></svg> </a>
                <a href="https://www.facebook.com/immigrationlawyerklf/" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" className="Elelogof">    <path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"/></svg> </a>
                <a href="https://www.instagram.com/immigrationlawyerklf/" target="_blank"> <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="Elelogof"> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12ZM17.5 8C18.3284 8 19 7.32843 19 6.5C19 5.67157 18.3284 5 17.5 5C16.6716 5 16 5.67157 16 6.5C16 7.32843 16.6716 8 17.5 8Z" fill="none"/> </svg> </a>
                <a href="https://www.tiktok.com/@immigrationlawyerklf" target="_blank"><svg fill="#000000" className="Elelogof" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path></svg></a>
                         
                         
                          </div>
            </div>
            {/* <button> Services </button>
            <button> Book Service </button> */}
            </section>
            </section>
            <div className="fTitle"> Services </div>
                <div className="Background"></div>
            <section className="Services">
                <EmblaCarousel slides={SLIDES} options={OPTIONS} />
            </section>
            <div className="fTitle"> About Us </div>

            <section className="fAboutus">
            <div className="Modern"> 
            <div className="Cardcontent">
            <div className="Carddescription">
            The legal team at Kasso Law Firm is dedicated to reuniting families and empowering them to achieve the American dream. With years of immigration law experience, our team offers comprehensive guidance through the complex immigration legal process. We provide personalized legal representation to effectively navigate the immigration system.

<br></br> <br></br>
<br></br>
At Kasso Law Firm, we are deeply committed to reuniting families. With extensive experience in immigration law, we work tirelessly to support you and your loved ones throughout the process. 


Our immigration law firm has extensive experience handling a wide range of immigration matters, including family-based petitions, marriage-based and fiancé visas, citizenship and naturalization, U-visas, VAWA, T-visas, inadmissibility waivers, DACA and advance parole, asylum, and more.


Call us today at (650) 770-6557 or (209) 740-2940 to discuss your options and get started. <b>¡Hablamos Español!</b>
</div>
</div>
        </div>
        <div className="Card"> 
            <div className="Cardcontent" style={{justifyContent:'center', alignItems:"center"}}>
                <div className="Cardtitle" style={{fontSize:"4vh"}}> Denny Kasso, Esq. </div>
                <div className="Cardtitle" style={{fontSize:"4vh"}}> Ryan Kasso, Master of Laws </div>

                <br></br>
                <br></br>

                <Link to={"/ourteam#top"} className="Link">  <div> <button style={{width:"30vw"}}> Learn More About <br></br>Our Team </button></div></Link>
                <br></br>
                <br></br>

            </div>

            <img className="Cardimg" src={require("../assets/D. & R Kasso white background.JPG")} alt="Kasso"/>

        </div>
    
            </section>
            <section className="fContactus">
            <div className="Modern"> 
                    <img className="Cardimgf"  src={require("../assets/office.jpg")} alt="Kasso"/>
                    <div className="Cardcontent">
                    <div className="Title"> Contact Us</div>
                    <div className="Contactus">
                    <div className="Location">
                    Kasso Law Firm <br></br>
                    35 E. 10th Street, Suite K <br></br>
                    Tracy, CA 95376        </div>
                    <div className="Holder">
                    <div className="sInfoEle">


                            <p className="P"> Tel: (650) 770-6557 <br></br>or (209) 740-2940</p>   
                            </div>
                            <div className="sInfoEle">
                                        

                            <p className="P">Email: info@kassolaw.com</p>
                            </div>
                            </div>
                            </div>
                            <div className="Text">To schedule a consultation, please call our office at (650) 770-6557 or (209) 740-2940. <br></br> <br></br>
                    A member of our team will call you to assist in scheduling your consultation.
                    </div>
                    </div>
                    </div>
            </section>
            </div>
        </>
    )
};