import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import { HashLink as Link } from 'react-router-hash-link';


const EmblaCarousel = (props) => {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [scrollProgress, setScrollProgress] = useState(0)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const onScroll = useCallback((emblaApi) => {
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
    setScrollProgress(progress * 100)
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onScroll(emblaApi)
    emblaApi
      .on('reInit', onScroll)
      .on('scroll', onScroll)
      .on('slideFocus', onScroll)
  }, [emblaApi, onScroll])

  const titles = [
    "Family-based Petitions",
    "Marriage-based Green Cards",
    "Fiancé Visas",
    "Citizenship & Naturalization",
    "U-visas",
    "VAWA",
    "T-visas",
    "Inadmissibility Waivers",
    "DACA",
    "Advance Parole",
    "Asylum"
  ];


  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
              <div className='Cardcar'>
               <div className='Cardcontentcar' style={{padding:"none", margin:"none"}}> 
               <img className="Cardimgcar" src={require(`../assets/${index+1}.jpg`)} alt="Kasso"/>
                <div className='Wrapper'>
                <div className='Cardtitlecar'>  {titles[index]} </div>
                <Link to={`/services#${index+1}`} className="Link">  <div> <button > Learn More</button></div></Link>
               </div>
               </div>
              
              </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__progress">
          <div
            className="embla__progress__bar"
            style={{ transform: `translate3d(${scrollProgress}%,0px,0px)` }}
          />
        </div>
      </div>
    </div>
  )
}

export default EmblaCarousel
