import React from "react";
import './aboutus.css';


export const Aboutus = () => {
    return(
        <>
        <div className="Pagecontent">

        <div className="Modern"> 
        <img className="Cardimgf"  src={require("../assets/office.jpg")} alt="Kasso"/>
        <div className="Cardcontent">
        <div className="Title"> Contact Us</div>
        <div className="Contactus">
        <div className="Location">
        Kasso Law Firm <br></br>
        35 E. 10th Street, Suite K <br></br>
        Tracy, CA 95376        </div>
        <div className="Holder">
        <div className="sInfoEle">


                <p className="P"> Tel: (650) 770-6557 <br></br>or (209) 740-2940</p>   
                </div>
                <div className="sInfoEle">
                            

                <p className="P">Email: info@kassolaw.com</p>
                </div>
                </div>
        </div>
        <div className="Text">To schedule a consultation, please call our office at (650) 770-6557 or (209) 740-2940. <br></br> <br></br>
A member of our team will call you to assist in scheduling your consultation.
 </div>
        </div>
        </div>
        {/* <iframe width="600" height="450" loading="lazy" allowfullscreen
src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJqZfWI74jkIARNm26jceL-lI&key=..."></iframe> */}
         </div>
        </>
    )
};